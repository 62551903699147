<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No tienes asignado ningún parte de trabajo"
      :multiselect="estado === PARTE_TRABAJO.estados.pendiente"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :selecting.sync="selecting"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idparte_trabajo"
      @click-item="clickListItem"
      @click-toolbar-option="clickToolbarOption"
      @open-filter="openFilter"
      @multiselect-item="multiselectItem"
    >
      <template
        #selectedOptions="slotProps"
      >
        <v-btn
          v-show="slotProps.selecting"
          icon
          @click.stop="descargarSeleccionados"
        >
          <v-icon>{{ $vuetify.icons.values.download }}</v-icon>
        </v-btn>
      </template>
      <template
        #listItem="slotProps"
      >
        <parte-list-item
          v-if="dbReady"
          :item="slotProps.item"
          :selecting="slotProps.selecting"
          :in-ids-partes-offline="inIdsPartesOffline"
          :selected-list-items="selectedListItems"
        >
          <template
            #actions="slotProps"
          >
            <v-btn
              v-if="estado === PARTE_TRABAJO.estados.pendiente && !slotProps.item.offline && slotProps.item.asignadoAMi"
              icon
              @click.stop="clickDescargarParte(slotProps.item)"
            >
              <v-icon>{{ $vuetify.icons.values.download }}</v-icon>
            </v-btn>
          </template>
        </parte-list-item>
      </template>
    </b10-list>
    <parte-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-map-markers
      :markers="mapMarkers"
      title="Mapa asignados"
      :showing.sync="showingDialogs.map"
      icon-fieldname="icon"
      @bounds-changed="mapBoundsChanged"
      @click-marker="clickMapMarker"
    />
    <b10-map-marker-info
      :title="mapMarkerInfo.title"
      :subtitle="mapMarkerInfo.subtitle"
      :lat="mapMarkerInfo.lat"
      :lng="mapMarkerInfo.lng"
      :showing.sync="showingDialogs.mapMarkerInfo"
    >
      <template slot="cardExtraButtons">
        <v-btn
          color="info"
          dark
          @click="clickVerParte(mapMarkerInfo.idparteTrabajo)"
        >
          <v-icon left>
            {{ $vuetify.icons.values.parte }}
          </v-icon> Ver parte
        </v-btn>
      </template>
    </b10-map-marker-info>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import { get, sync } from 'vuex-pathify'
import Data from './ParteAsignadosListData'
import ParteListItem from '../components/ParteListItem'
import ParteLegend from '../components/ParteLegend'
import { downloadParteTrabajo, downloadTablasGlobales } from '@/sync/download'
import { cleanParteTrabajo } from '@/sync/upload'
import { PARTE_TRABAJO } from '@/utils/consts'
import { currentDateTime, toDateTime } from '@/utils/date'
import _ from '@/utils/lodash'
import { markerIcons } from '@/utils/maps'
import { tablasGlobalesDescargadas } from '@/offline/database'

const pageStoreName = 'pagesParteAsignadosList'

export default {
  components: {
    ParteListItem, ParteLegend
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    estado: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
      },
      inIdsPartesOffline: [],
      showingDialogs: {
        legends: false,
        map: false,
        mapMarkerInfo: false,
      },
      PARTE_TRABAJO,
      mapMarkerInfo: {
        title: '',
        subtitle: '',
        lat: null,
        lng: null,
      },
      mapMarkers: [],
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    selectedListItems: get(`${pageStoreName}/selectedListItems`),
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdalmacen: get('usuario/idalmacen'),
    usuarioUltimaDescargaGlobales: sync('usuario/ultimaDescargaGlobales'),
    networkOnline: get('network/online'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.estado === PARTE_TRABAJO.estados.pendiente) {
        this.title = 'Asignados'
      } else {
        this.title = 'Finalizados'
      }
      await this.loadOfflineStatus()
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectAsignados(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.usuarioIdtecnico,
          this.estado
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectAsignadosRows(this, dirty.modified, this.usuarioIdtecnico, this.estado)
        await this.reloadItemsBase(dataset, dirty, 'idparte_trabajo')
      } finally {
        this.loadingData = false
      }
    },
    clickParte(idparteTrabajo) {
      if (this.inIdsPartesOffline.indexOf(idparteTrabajo) >= 0) {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'offline__parte-trabajo-view',
          params: {
            idparte_trabajo: idparteTrabajo
          }
        })
      } else {
        this.rememberState = true
        this.rememberScroll = true
        this.$appRouter.push({
          name: 'partes__parte-trabajo-view',
          params: {
            idparte_trabajo: idparteTrabajo
          }
        })
      }
    },
    clickListItem (item) {
      if (this.networkOnline) {
        this.clickParte(item.data.idparte_trabajo)
      } else {
        this.$alert.showSnackbarError('No tienes conexión a internet.')
      }

    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.map) {
        this.showingDialogs.map = true
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.zona.items.length === 0 &&
        this.filter.tactuacion.items.length === 0 &&
        this.filter.tsistema.items.length === 0 &&
        this.filter.tmotivoBloqueoOt.items.length === 0 &&
        this.filter.tecnico.items.length === 0
      ) {
        const resp = await Data.selectFilterLookups(this)
        await this.setStoreProperty('filter@zona.items', resp.data.selectZona.result.dataset)
        await this.setStoreProperty('filter@tactuacion.items', resp.data.selectTactuacion.result.dataset)
        await this.setStoreProperty('filter@tsistema.items', resp.data.selectTsistema.result.dataset)
        await this.setStoreProperty('filter@tmotivoBloqueoOt.items', resp.data.selectTmotivoBloqueoOt.result.dataset)
        await this.setStoreProperty('filter@tecnico.items', resp.data.selectTecnico.result.dataset)
      }
    },
    async loadOfflineStatus () {
      this.inIdsPartesOffline = await this.$offline.parteTrabajo.inIdsPartesOffline()
    },
    async descargarParte (item, many) {
      try {
        // hour * minute * second * milisecond
        const ayer = Date.now() - (24 * 60 * 60 * 1000)
        const globalesDescargados = await tablasGlobalesDescargadas(this)
        if (!this.usuarioUltimaDescargaGlobales || !globalesDescargados || toDateTime(this.usuarioUltimaDescargaGlobales) < ayer) {
          await downloadTablasGlobales(this)
          this.usuarioUltimaDescargaGlobales = currentDateTime()
        }
        await downloadParteTrabajo(this, item.idparte_trabajo, this.usuarioIdalmacen, this.usuarioIdtecnico)
        if (!many) {
          this.$alert.showSnackbarSuccess('Parte de trabajo descargado')
        }
      } catch (error) {
        await cleanParteTrabajo(this, item.idparte_trabajo)
        console.error(`Error en el parte de trbajo: ${item.idparte_trabajo}`)
        throw new Error(error)
      }
    },
    async descargarSeleccionados () {
      const selectedItems = await this.selectedItems
      for (const item of selectedItems) {
        if (this.inIdsPartesOffline.indexOf(item.idparte_trabajo) === -1 && !item.descargado_por_mi) {
          await this.descargarParte(item, true)
        }
      }
      await this.clearSelection()
      await this.loadOfflineStatus()
    },
    multiselectItem (data) {
      if (this.inIdsPartesOffline.indexOf(data.item.idparte_trabajo) === -1 && !data.item.descargado_por_mi) {
        data.multiselectItemDone(data.item)
      }
    },
    async clickDescargarParte (item) {
      await this.descargarParte(item)
      await this.loadOfflineStatus()
    },
    clickMapMarker (markerData) {
      this.mapMarkerInfo.title = this.$online.parteTrabajo.title(markerData)
      this.mapMarkerInfo.subtitle = this.$online.parteTrabajo.subtitle(markerData)
      this.mapMarkerInfo.lat = markerData.latitud
      this.mapMarkerInfo.lng = markerData.longitud
      this.mapMarkerInfo.idparteTrabajo = markerData.idparte_trabajo
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
    },
    async mapBoundsChanged (bounds) {
      let [markers] = await Data.selectAsignados(
        this,
        this.filter,
        this.search,
        this.sorter,
        0,
        this.usuarioIdtecnico,
        this.estado,
        bounds,
        0
      )
      markers = _.map(markers, (marker) => {
        marker.icon = markerIcons.parteTrabajo
        return marker
      })
      this.mapMarkers = markers
    },
    async clickVerParte (id) {
      this.showingDialogs.map = !this.showingDialogs.map
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
      this.clickParte(id)
    }
  }
}
</script>
